<template>
  <v-row>
    <v-col>
      <v-card outlined :loading="form.loading" :disabled="form.loading">
        <v-card-text>
          Add Member Stocks
          <v-autocomplete
            label="Member"
            v-model="form.member_id"
            :items="allMembers"
            item-text="name"
            item-value="id"
            @change="getStocks"
          ></v-autocomplete>
          <v-form ref="stocks" v-if="form.data">
            <v-row v-for="(stock, i) in form.data" :key="i">
              <v-col>
                <v-autocomplete
                  label="Product"
                  v-model="stock.product_name"
                  :items="form.products"
                  item-text="name"
                  item-value="name"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  label="Quantity"
                  suffix="pcs"
                  v-model.number="stock.quantity"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      icon
                      color="deep-orange accent-2"
                      @click="handleStocks('remove', i)"
                      v-if="form.data.length > 1"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="align-center">
              <v-tooltip right transition="slide-x-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    depressed
                    v-on="on"
                    color="indigo accent-2"
                    @click="handleStocks('add', null)"
                  >
                    <v-icon color="white">mdi-archive-plus</v-icon>
                  </v-btn>
                </template>

                <span>Add Product</span>
              </v-tooltip>

              <v-spacer></v-spacer>

              <v-tooltip left transition="slide-x-reverse-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    depressed
                    v-on="on"
                    color="indigo accent-2"
                    @click="saveData"
                  >
                    <v-icon color="white">mdi-content-save</v-icon>
                  </v-btn>
                </template>

                <span>Save Data</span>
              </v-tooltip>
            </v-row>
          </v-form>

          <v-alert
            dense
            prominent
            text
            icon="mdi-information-variant"
            color="info"
            v-if="form.isEmpty && !form.data"
          >
            <v-row no-gutters class="align-center">
              Member has no stocks yet.
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                @click="handleStocks('new', null)"
              >
                <span>add stock</span>
              </v-btn>
            </v-row>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-col>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        isEmpty: false,
        loading: false,
        member_id: null,
        data: null,
        products: null,
        rules: {
          product: [(v) => !!v || "Product is required"],
          quantity: [(v) => !!v || "Quantity is required"],
        },
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["newStock"]),
    async getStocks() {
      this.form.loading = true;
      let id = this.form.member_id;

      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/member/${id}/stocks`, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.length == 0) {
            this.form.loading = false;
            this.form.isEmpty = true;
          } else {
            this.form.loading = false;
            this.form.data = [{ product_name: null, quantity: null }];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getProducts() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/products`)
        .then((response) => {
        //   console.log(response.data);
          this.form.products = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleStocks(act, i) {
      switch (act) {
        case "add":
          this.form.data.push({ product_name: null, quantity: null });
          break;
        case "remove":
          this.form.data.splice(i, 1);
          break;
        case "new": {
          if (!this.form.data) {
            this.form.data = [{ product_name: null, quantity: null }];
          }
        }
      }
    },
    saveData() {
      this.form.loading = true;
      let isValid = this.$refs.stocks.validate();

      if (isValid) {
        for (let i = 0; i < this.form.data.length; i++) {
          let data = {
            member_id: this.form.member_id,
            product_name: this.form.data[i].product_name,
            quantity: this.form.data[i].quantity,
          };
          this.newStock(data).then(() => {
            if (i == this.form.data.length - 1) {
              this.form.loading = false;
              this.showNotification("success");
            }
          });
        }
      } else {
        this.form.loading = false;
        this.showNotification("error");
      }
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.text = "Successfuly Added Data";
          this.snackbar.icon = "mdi-check-circle";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.text = "Error Adding Data";
          this.snackbar.icon = "mdi-close-circle";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allMembers"]),
  },

  mounted() {
    this.getProducts();
  },
};
</script>